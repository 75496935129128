import React, { createContext, useContext, useState, Suspense } from 'react';

import { IModal, IModalBase } from '../models/modal.model';
import { fallbackFactory } from '../utils/fallback-factory';

interface IModalContext {
    modals: IModal[];
    addModal(data: Omit<IModal, 'modalId'>): number;
    removeModal(modalId: number): void;
}

const initialModalContext: IModalContext = {
    modals: [],
    addModal() {
        throw Error();
    },
    removeModal() {
        throw Error();
    },
};

const ModalContext = createContext<IModalContext>(initialModalContext);

export const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [modals, setModals] = useState<IModal[]>([]);

    const addModal: IModalContext['addModal'] = (data) => {
        const modalId = new Date().getTime();
        setModals((prevModals) => {
            return [...prevModals, { modalId, ...data }];
        });
        return modalId;
    };

    const removeModal: IModalContext['removeModal'] = (modalId) => {
        setModals((prevModals) => {
            return prevModals.filter((modal) => modal.modalId !== modalId);
        });
    };

    return (
        <ModalContext.Provider value={{ modals, addModal, removeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModalContext was used outside of its Provider');
    }

    return context;
};

export const ModalContextConsumer = () => {
    const { modals } = useModalContext();

    if (!modals.length) return null;

    return (
        <>
            {modals.map((modal) => {
                const Modal = React.lazy(modalFactory(modal.modalKey));
                return (
                    <Suspense key={`modal-${modal.modalId}`} fallback={null}>
                        <Modal modalId={modal.modalId} {...modal.modalProps} />
                    </Suspense>
                );
            })}
        </>
    );
};

function modalFactory(componentName: string) {
    return fallbackFactory<IModalBase>(
        () => import(`../components/modals/${componentName}`),
        () => import(`../components/modals/empty`)
    );
}
