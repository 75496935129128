import React from 'react';
import { AnimatePresence } from 'framer-motion';

export const wrapPageElement = ({ element }) => {
    const handleExitComplete = () => {
        const hashRaw = window.location.hash;
        if (!hashRaw) return;
        const hash = hashRaw.substring(1);
        setTimeout(() => {
            const element = document.getElementById(hash);
            element.scrollIntoView();
        }, 500);
    };
    return (
        <AnimatePresence exitBeforeEnter={true} onExitComplete={handleExitComplete}>
            {element}
        </AnimatePresence>
    );
};
