import React from 'react';

type ReactComponentModule<Props> = { default: React.ComponentType<Props> };

export function fallbackFactory<Props>(
    importComponent: () => Promise<ReactComponentModule<Props>>,
    importFallback: () => Promise<ReactComponentModule<Props>>
) {
    return () => {
        return new Promise<ReactComponentModule<Props>>(async (resolve, reject) => {
            try {
                const module = await importComponent();
                resolve(module);
            } catch (err) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                if (err.code === 'MODULE_NOT_FOUND') {
                    resolve(importFallback());
                } else {
                    reject(err);
                }
            }
        });
    };
}
