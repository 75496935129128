import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import './src/styles/reset.scss';
import './src/styles/fonts.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { ModalContextProvider } from './src/contexts/modal.context';
import { PAGE_EXIT_ANIMATION_DURATION } from './src/config/consts';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    return <ModalContextProvider>{element}</ModalContextProvider>;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    prevRouterProps,
    routerProps,
    getSavedScrollPosition,
}) => {
    const prevLocation = prevRouterProps?.location;
    const { location } = routerProps;
    const positionToScrollAfterAnimation = getSavedScrollPosition(location);

    if (
        (location.hash && !prevLocation) ||
        (location.hash && prevLocation?.pathname !== location.pathname)
    ) {
        setTimeout(() => {
            const elementId = location.hash.substring(1);
            document.getElementById(`${elementId}`)?.scrollIntoView();
        }, PAGE_EXIT_ANIMATION_DURATION * 1000 + 100);
        return false;
    }

    // By having a page transition animation, we need to delay the scroll to the memorized position
    // by the time it takes for the exit animation (plus some extra),
    // otherwise we will not get to the memorized position
    if (
        !positionToScrollAfterAnimation ||
        (Array.isArray(positionToScrollAfterAnimation) && positionToScrollAfterAnimation[1] === 0)
    )
        return true;

    if (!location.hash) {
        setTimeout(() => {
            window.scrollTo(...positionToScrollAfterAnimation);
        }, PAGE_EXIT_ANIMATION_DURATION * 1000 + 100);
    }

    return false;
};
